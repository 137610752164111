.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //height: 80px;
    width: 100vw;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Test with a strong shadow */
    position: relative;
    z-index: 10;


    a {
        text-decoration: none;
    }

    img {
        color: grey;
        margin-left: 50px;
        height: 40px;
        font-size: 25px;
    }

    p {
        color: #00263E;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 22px;
        margin-right: 50px;
    }
}

.header__logo {
    display: flex;
    flex-direction: row;
    align-items: center; /* Aligns items vertically in the center */

    p {
        margin-left: 8px;
        color: #00263E;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 22px;
    }
}

.header__title {
    h1 {
        margin-left: 8px;
        color: #00263E;
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
    }
    p {
        margin-left: 8px;
        color: grey;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
    }
}