.page {
    background-color: white;
    height: 100%;
    width: 100%;
    padding: 10pt;
}

.info__heading {
    color: #00263e;
    font-family: Inter, sans-serif;
    padding: 0;
    margin: 0 8px;
    line-height: 22px;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

.info__description {
    color: #00263e;
    font-family: Inter, sans-serif;
    padding: 0;
    margin: 0 8px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
}

.info__disclaimer {
    color: #00263e;
    font-family: Inter, sans-serif;
    font-size: 12px;
    padding: 0;
    margin: 0 8px;
    font-weight: 400;
    line-height: 1.4;
}

.info__disclaimer__heading {
    color: #00263e;
    font-family: Inter, sans-serif;
    padding: 0 0 4px 0;
    margin: 0 8px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 600;
}
