.page {
    background-color: white;
    height: 100%;
    width: 100%;

    p {
        display: flex;
        justify-content: center;
    }
}
